export const config: NetworkConfig = {
  eth: {
    network: {
      symbol: "ETH",
      chainId: 1,
      chainName: "Ethereum",
      chainIcon: "ethereum",
      chainTargetId: 1,
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
      blockExplorerUrl: "https://etherscan.io",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      UDO: "0xea3983Fc6D0fbbC41fb6F6091f68F3e08894dC06",
      USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      FRONT: "0xf8C3527CC04340b208C854E985240c02F7B7793f",
      OCEAN: "0x985dd3d42de1e256d09e1c10f112bccb8015ad41",
      DAI: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      COMP: "0xc00e94Cb662C3520282E6f5717214004A7f26888",
      AMPL: "0xD46bA6D942050d489DBd938a2C909A5d5039A161",
      WBTC: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      AAVE: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
      ANT: "0x960b236A07cf122663c4303350609A66A7B288C0",
      BNT: "0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C",
      CRV: "0xD533a949740bb3306d119CC777fa900bA034cd52",
      CVC: "0x41e5560054824eA6B0732E656E3Ad64E20e94E45",
      GNO: "0x6810e776880C02933D47DB1b9fc05908e5386b96",
      GRT: "0xc944E90C64B2c07662A292be6244BDf05Cda44a7",
      LINK: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
      BAND: "0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55",
      IXS: "0x73d7c860998ca3c01ce8c808f5577d94d545d1b4",
      WEWT: "0x1ad048213a0b268d7cdd4d746ebd6f7975b26cc2",
      EJS: "0x96610186f3ab8d73ebee1cf950c750f3b1fb79c2",
    },
  },
  bsc: {
    network: {
      symbol: "BSC",
      chainId: 56,
      chainName: "Smart Chain",
      chainIcon: "binance",
      chainTargetId: 2,
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrl: "https://bsc-dataseed.binance.org/",
      blockExplorerUrl: "https://bscscan.com",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      USDT: "0x55d398326f99059ff775485246999027b3197955",
      BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      CAKE: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
      BAKE: "0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5",
      DOT: "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
      MDX: "0x9c65ab58d8d978db963e63f2bfb7121627e3a739",
      XVS: "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
      SAFEMOON: "0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3",
      EOS: "0x56b6fb708fc5732dec1afc8d8556423a2edccbd6",
      BCH: "0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
      ADA: "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
      BNB: "0x1C6F13d153EA1d53b077657F0A0e98881Ccc5112",
      WEWT: "0x6602267589a2a952316189b6ecae9e5eb117bbd5",
      WEJS: "0x09f423aC3C9baBBfF6F94D372b16E4206e71439f",
    },
  },
  avalanche: {
    network: {
      symbol: "AVAX",
      chainId: 43114,
      chainName: "Avalanche C-Chain",
      chainIcon: "avalanche",
      chainTargetId: 3,
      nativeCurrency: {
        name: "AVAX",
        symbol: "AVAX",
        decimals: 18,
      },
      rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
      blockExplorerUrl: "https://cchain.explorer.avax.network",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      USDT: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
      DAS: "0xa47a05ed74f80fa31621612887d26df40bcf0ca9",
      WAVAX: "0xA4C174FdCff7FDaC3CD57bcCb8df1ACB28224f53",
      WEWT: "0xd96d422B742405C48112269F03f11F42b3C211e2",
    },
  },
  polygon: {
    network: {
      symbol: "MATIC",
      chainId: 137,
      chainName: "Polygon Matic",
      chainIcon: "matic",
      chainTargetId: 4,
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      rpcUrl: "https://rpc-mainnet.maticvigil.com",
      blockExplorerUrl: "https://polygonscan.com",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      CHUM: "0x2e2DDe47952b9c7deFDE7424d00dD2341AD927Ca",
      TTPS: "0xa6B9B468C98Fc97058704F14C9bD864661e93ea8",
      USDC: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      QUICK: "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
      DAI: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      WIXS: "0x1BA17C639BdaeCd8DC4AAc37df062d17ee43a1b8",
      WMATIC: "0x08e30B616ec2f03ecAfBdf9654F24a4bd2F2c95A",
      WEWT: "0x6db7f16d8dd1e120acf2965b8c86d8bdec8c4add",
    },
  },
  ewc: {
    network: {
      symbol: "EWC",
      chainId: 246,
      chainName: "Energy Web Chain",
      chainIcon: "ewc",
      chainTargetId: 5,
      nativeCurrency: {
        name: "EWT",
        symbol: "EWT",
        decimals: 18,
      },
      rpcUrl: "https://rpc.energyweb.org",
      blockExplorerUrl: "https://explorer.energyweb.org",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      SLR: "0x26E4991a72728b1a9B1044345e5bF9293E0A1434",
      xSUSU: "0x1C41d2223dc95605fc4395294e65f170A4Fb1b40",
      SUSU: "0x9cd9CAECDC816C3E7123A4F130A91A684D01f4Dc",
      SMUDGE: "0x3Dae074A5B125B9847e41b5Ee20aC86D92ab77b1",
      DAI: "0x3862F260e94904aaAe628DdF427b1F662652BBD2",
      OCEAN: "0x593122AAE80A6Fc3183b2AC0c4ab3336dEbeE528",
      WEWT: "0x0DCF00f9223DCb4d4CE73BF08D1050ee8C5A143D",
    },
    EWTB: {
      ETH: "0x178c820f862B14f316509ec36b13123DA19A6054",
    },
  },
  arbitrum: {
    network: {
      symbol: "ARBITRUM",
      chainId: 42161,
      chainName: "Arbitrum",
      chainIcon: "arbitrum",
      chainTargetId: 6,
      nativeCurrency: {
        name: "AETH",
        symbol: "AETH",
        decimals: 18,
      },
      rpcUrl: "https://arb1.arbitrum.io/rpc",
      blockExplorerUrl: "https://arbiscan.io",
      refreshClaimTimestamp: 15000,
    },
    tokenAddresses: {
      USDT: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
      USDC: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
      DAI: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
      UNI: "0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
      LINK: "0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
      SUSHI: "0xd4d42f0b6def4ce0383636770ef773390d85c61a",
      WEWT: "0xef11d4c056ec3e3b4c00a555f7cf08b9bbe6422b",
    },
  },
  optimistic: {
    network: {
      symbol: "OPTIMISTIC",
      chainId: 10,
      chainName: "Optimistic",
      chainIcon: "optimistic",
      chainTargetId: 7,
      nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrl: "https://mainnet.optimism.io",
      blockExplorerUrl: "https://optimistic.etherscan.io",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WALBT: "0x276cf9e7a43d1B9422dEcd1f1d2608f3D588CAad",
      WEWT: "0x6c6c4017547120cdd18898abd104e9574a66e507",
      ABOETH: "0x4A0c2D99D2b8028F0D8facDe9aC0e631614F4293",
    },
  },
  moonbeam: {
    network: {
      symbol: "MOONBEAM",
      chainId: 1284,
      chainName: "Moonbeam",
      chainIcon: "moonbeam",
      chainTargetId: 8,
      nativeCurrency: {
        name: "DEV",
        symbol: "DEV",
        decimals: 18,
      },
      rpcUrl: "https://rpc.api.moonbeam.network",
      blockExplorerUrl: "https://moonscan.io",
      refreshClaimTimestamp: 15000,
    },

    tokenAddresses: {
      WALBT: "0xB2991Ae5FE9593aB2978A2388acd4ce19B053a12",
      GLMR: "0xaE366B7C5e59822A9AAEbfDF7744F6445e5211D1",
      WEWT: "0x5b1354c1Dc049A68344eF0CE4da61d8F616Ae7cb",
    },
  },
};
