import { BigNumber, ethers } from "ethers";

export const getSendEstimation = async (
  sdk: any,
  targetChainId: number,
  tokenAddress: string,
  amount: BigNumber,
  receiver: string,
  feeTokenAddress: string,
  bridgedTokenIsNativeToken: boolean
) => {
  const nativeToken = await sdk.routerContract.wrappedToNativeToken(
    tokenAddress
  );
  if (feeTokenAddress) {
    // Check of fee token is native token
    let fee =
      feeTokenAddress === sdk.proxyRouterContract.address
        ? await sdk.getProxyFee(feeTokenAddress)
        : 0;

    // Check if bridged token is native token
    if (bridgedTokenIsNativeToken) {
      fee = amount.add(fee);
    }
    if (nativeToken.chainId == 0) {
      return await sdk.proxyRouterContract.estimateGas.lock(
        feeTokenAddress,
        targetChainId,
        tokenAddress,
        amount,
        receiver,
        { value: fee }
      );
    } else if (nativeToken.chainId !== targetChainId) {
      return await sdk.proxyRouterContract.estimateGas.burnAndTransfer(
        feeTokenAddress,
        targetChainId,
        tokenAddress,
        amount,
        receiver,
        { value: fee }
      );
    }

    return await sdk.proxyRouterContract.estimateGas.burn(
      feeTokenAddress,
      tokenAddress,
      amount,
      receiver,
      { value: fee }
    );
  } else {
    if (nativeToken.chainId == 0) {
      return await sdk.routerContract.estimateGas.lock(
        targetChainId,
        tokenAddress,
        amount,
        receiver
      );
    } else if (nativeToken.chainId !== targetChainId) {
      return await sdk.routerContract.estimateGas.burnAndTransfer(
        targetChainId,
        tokenAddress,
        amount,
        receiver
      );
    }

    return await sdk.routerContract.estimateGas.burn(
      tokenAddress,
      amount,
      receiver
    );
  }
};

export const getApproveEstimation = async (
  sdk: any,
  tokenAddress: string,
  amount: BigNumber,
  bridgedTokenIsNativeTokenOrNonDefaultFee: boolean
) => {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    sdk.erc20ABI,
    sdk.signerOrProvider
  );
  return await tokenContract.estimateGas.approve(
    bridgedTokenIsNativeTokenOrNonDefaultFee
      ? sdk.proxyRouterContract.address
      : sdk.routerContract.address,
    amount
  );
};

export const signSendWithPermit = async (
  sdk: any,
  tokenAddress: string,
  owner: string,
  deadline: number,
  chainId: number,
  value: BigNumber,
  isNonDefaultFeeTokenSelected: boolean
) => {
  const tokenContract = new ethers.Contract(
    tokenAddress,
    sdk.erc20ABI,
    sdk.signerOrProvider
  );

  const nonce = await tokenContract.nonces(owner);

  const Permit = [
    { name: "owner", type: "address" },
    { name: "spender", type: "address" },
    { name: "value", type: "uint256" },
    { name: "nonce", type: "uint256" },
    { name: "deadline", type: "uint256" },
  ];

  const domain = {
    name: await tokenContract.name(),
    version: "1",
    chainId,
    verifyingContract: tokenContract.address,
  };

  const message = {
    owner: owner,
    spender: isNonDefaultFeeTokenSelected
      ? sdk.proxyRouterAddress
      : sdk.routerAddress,
    value,
    nonce,
    deadline,
  };

  const signature = await sdk.signerOrProvider._signTypedData(
    domain,
    { Permit },
    message
  );

  return ethers.utils.splitSignature(signature);
};
