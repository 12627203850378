import { getNetworkByChainTargetId } from "utils/network";

async function getFormattedSdkNftClaims(
  sdk: any,
  account: string,
  chainId: number,
  networkOptions: Network[]
): Promise<{ [transmissionId: string]: FormattedNftClaims }> {
  const claims = await sdk.getNftClaims(chainId, account);
  const formattedNftClaims: { [transmissionId: string]: FormattedNftClaims } =
    {};
  for (const claim of claims) {
    claim.signatures = claim.signatures.map(
      (signature: string) => "0x" + signature
    );
    if (!formattedNftClaims[claim.transmissionId]) {
      formattedNftClaims[claim.transmissionId] = {
        sourceNetwork: getNetworkByChainTargetId(
          claim.sourceChainId,
          networkOptions
        ),
        targetNetwork: getNetworkByChainTargetId(
          claim.targetChainId,
          networkOptions
        ),
        items: [claim],
        majorityReached: [claim.majorityReached],
        delivered: [claim.delivered],
      };
    } else {
      formattedNftClaims[claim.transmissionId].items.push(claim);
      // to check if majority was reached
      // formattedNftClaims[claim.transmissionId].majorityReached.every((e: boolean) => e)
      formattedNftClaims[claim.transmissionId].majorityReached.push(
        claim.majorityReached
      );
      formattedNftClaims[claim.transmissionId].delivered.push(claim.delivered);
    }
  }
  return formattedNftClaims;
}

function getNftClaimCounters(nftClaims: any[]) {
  let counterClaimed = 0;
  let counterNotClaimed = 0;

  if (nftClaims) {
    nftClaims.map((NftClaim: any) => {
      const isDelivered: boolean = NftClaim?.delivered[0];
      const isMajorityReached: boolean = NftClaim?.majorityReached[0];
      if (isDelivered && isMajorityReached) {
        counterClaimed++;
      } else {
        counterNotClaimed++;
      }
    });
  }
  return { counterClaimed, counterNotClaimed };
}

export { getFormattedSdkNftClaims, getNftClaimCounters };
