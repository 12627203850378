import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";

import { Stepper } from "@stichting-allianceblock-foundation/components";
import { TOKEN_FEE_DECIMALS } from "configs/constants";
import { NETWORK_CONFIG } from "configs/networks";
import { ethers } from "ethers";
import { useGlobalContext } from "hooks/useGlobalContext";
import {
  formatCurrency,
  fromWei,
  getBlockExplorerNameByChainId,
  toBN,
  toWei,
  TxStatus,
} from "utils";
import {
  currencySymbols,
  getCoingeckooIds,
  getTokenPrice,
} from "utils/coingecko";
import {
  getApproveEstimation,
  getSendEstimation,
  signSendWithPermit,
} from "utils/sdk";

import { StepBridgeButtons, StepDetails, StepReview } from "./Steps";

import "./StepperBridge.scss";

interface StepperBridgeProps {
  activeStep: number;
  wasTransactionCleared: boolean;
  serviceFeeTokenIndex: number;
  tokenSelectedBalance: string;
  sliderPercentage: number;
  selectedToken: TokenDetails;
  bridgeSteps: BridgeSteps;
  updateActiveStep: (step: 0 | 1) => void;
  updateBridgeSteps: (steps: BridgeSteps) => void;
  updateServiceFeeTokenIndex: (index: number) => void;
  updateWasTransferSuccessful: (wasSuccessful: boolean) => void;
  updateWasTransactionClear: (wasCleared: boolean) => void;
  updateTokenSelectedBalance: (balance: string) => void;
  updateSliderPercentage: (percentage: number) => void;
  updateSelectedToken: (token: TokenDetails) => void;
  clearBridgeTransaction: () => void;
}

const StepperBridge = ({
  activeStep,
  wasTransactionCleared,
  serviceFeeTokenIndex,
  tokenSelectedBalance,
  sliderPercentage,
  selectedToken,
  bridgeSteps,
  updateActiveStep,
  updateBridgeSteps,
  updateServiceFeeTokenIndex,
  updateWasTransferSuccessful,
  updateWasTransactionClear,
  updateSliderPercentage,
  updateSelectedToken,
  updateTokenSelectedBalance,
  clearBridgeTransaction,
}: StepperBridgeProps) => {
  const {
    sdk,
    config,
    currentNetwork,
    bridgeTransaction,
    serviceFeeOptions,
    setBridgeTransaction,
    setServiceFeeOptions,
  } = useGlobalContext();
  const { account, chainId, library } = useWeb3React();

  const [tokenList, setTokenList] = useState<TokenDetails[]>([]);
  const [txFeeEstimation, setTxFeeEstimation] = useState<string>("0.0");
  const [txFeeEstimationCurrency, setTxFeeEstimationCurrency] =
    useState<string>("($ 0.00)");
  const [
    isBridgeTransactionTokenNativeToken,
    setIsBridgeTransactionTokenNativeToken,
  ] = useState<boolean>(false);
  const [isNonDefaultFeeTokenSelected, setIsNonDefaultFeeTokenSelected] =
    useState<boolean>(false);
  const [notEnoughBalanceForServiceFee, setNotEnoughBalanceForServiceFee] =
    useState<boolean>(false);
  const [notDeployedWrappedToken, setNotDeployedWrappedToken] =
    useState<boolean>(false);
  const [nativeTokenByWrappedToken, setNativeTokenByWrappedToken] =
    useState<NativeTokenWithChainId>({} as NativeTokenWithChainId);

  const [isBridgeTokenApproved, setIsBridgeTokenApproved] =
    useState<boolean>(false);
  const [isServiceTokenApproved, setIsServiceTokenApproved] =
    useState<boolean>(false);
  const [
    nativeOrWrappedTokenSymbolToBridge,
    setNativeOrWrappedTokenSymbolToBridge,
  ] = useState<string>("");
  const [isTokenNonApprovable, setIsTokenNonApprovable] =
    useState<boolean>(false);
  const [isTokenEWTB, setIsTokenEWTB] = useState<boolean>(false);
  const [tokenIsNotWrapped, setTokenIsNotWrapped] = useState<boolean>(false);

  const maxAttempts: number = 10;

  const onSelectedFeeTokenChange = async (option: ServiceFeeToken) => {
    for (let index = 0; index < serviceFeeOptions.length; index++) {
      if (serviceFeeOptions[index]?.address === option?.address) {
        updateServiceFeeTokenIndex(index);
        break;
      }
    }

    setNotEnoughBalanceForServiceFee(false);
    setIsNonDefaultFeeTokenSelected(
      !["albt", "walbt"]?.includes(option?.symbol?.toLowerCase()) &&
        !isBridgeTransactionTokenNativeToken
    );
    setBridgeTransaction({
      ...bridgeTransaction,
      feeToken: {
        details: {
          name: option.name,
          symbol: option.symbol,
          decimals: option.decimals,
          icon: option.icon,
        },
        amount: option.amount,
        address: option.address,
      },
    });
  };

  const approveServiceFeeToken = async () => {
    if (sdk) {
      try {
        updateBridgeSteps({
          ...bridgeSteps,
          approveServiceFeeToken: false,
          processing: true,
        });

        const approveTx = await sdk.approveMaxInt(
          bridgeTransaction?.feeToken?.address,
          isBridgeTransactionTokenNativeToken || isNonDefaultFeeTokenSelected
        );

        const receiptTx = await sdk.signerOrProvider.sendTransaction(approveTx);

        updateBridgeSteps({
          ...bridgeSteps,
          approveServiceFeeToken: false,
          processing: true,
          blockExplorer: {
            name: getBlockExplorerNameByChainId(currentNetwork?.chainId),
            hash: receiptTx.hash,
            blockExplorerUrl: currentNetwork?.blockExplorerUrl,
          },
        });

        const receiptTxWait = await receiptTx.wait();

        if (receiptTxWait.status === TxStatus.READY) {
          setIsServiceTokenApproved(true);
          updateBridgeSteps({
            ...bridgeSteps,
            approveServiceFeeToken: false,
            approveBridgeToken:
              bridgeTransaction?.feeToken?.address ===
                bridgeTransaction?.token?.address ||
              isBridgeTransactionTokenNativeToken
                ? false
                : true,
            transfer:
              bridgeTransaction?.feeToken?.address ===
                bridgeTransaction?.token?.address ||
              isBridgeTransactionTokenNativeToken
                ? true
                : false,
            processing: false,
          });
        } else {
          updateBridgeSteps({
            ...bridgeSteps,
            approveServiceFeeToken: true,
            processing: false,
          });
        }
      } catch (err) {
        console.error(err);
        updateBridgeSteps({
          ...bridgeSteps,
          approveServiceFeeToken: true,
          processing: false,
        });
      }
    }
  };

  const approveBridgeToken = async () => {
    if (sdk) {
      try {
        updateBridgeSteps({
          ...bridgeSteps,
          approveBridgeToken: false,
          processing: true,
        });

        const approveTxDetails = await sdk.approve(
          bridgeTransaction?.token?.address,
          ethers.constants.MaxUint256,
          isNonDefaultFeeTokenSelected
        );

        const approveTx = await sdk.signerOrProvider.sendTransaction(
          approveTxDetails
        );

        updateBridgeSteps({
          ...bridgeSteps,
          approveBridgeToken: false,
          processing: true,
          blockExplorer: {
            name: getBlockExplorerNameByChainId(currentNetwork?.chainId),
            hash: approveTx.hash,
            blockExplorerUrl: currentNetwork?.blockExplorerUrl,
          },
        });

        const receiptTx = await approveTx.wait();

        if (receiptTx.status === TxStatus.READY) {
          setIsBridgeTokenApproved(true);
          updateBridgeSteps({
            ...bridgeSteps,
            approveBridgeToken: false,
            transfer: true,
            processing: false,
          });
        } else {
          updateBridgeSteps({
            ...bridgeSteps,
            approveBridgeToken: true,
            processing: false,
          });
        }
      } catch (err) {
        console.error(err);
        updateBridgeSteps({
          ...bridgeSteps,
          approveBridgeToken: true,
          processing: false,
        });
      }
    }
  };

  const sendBridgeToken = async () => {
    if (sdk) {
      let sendTxDetails, sendTx, receiptTx;

      try {
        updateBridgeSteps({
          ...bridgeSteps,
          transfer: false,
          processing: true,
        });

        if (isTokenNonApprovable) {
          const deadline = +new Date() + 60 * 60;
          const signature = await signSendWithPermit(
            sdk,
            bridgeTransaction?.token?.address,
            account as string,
            deadline,
            currentNetwork?.chainId,
            toWei(
              bridgeTransaction?.token?.amount,
              bridgeTransaction?.token?.details?.decimals
            ),
            isNonDefaultFeeTokenSelected
          );

          sendTxDetails = await sdk.sendWithPermit(
            bridgeTransaction?.network?.target?.chainTargetId,
            bridgeTransaction?.token?.address,
            toWei(
              bridgeTransaction?.token?.amount,
              bridgeTransaction?.token?.details?.decimals
            ),
            bridgeTransaction?.recipient,
            signature.v,
            signature.r,
            signature.s,
            deadline,
            isNonDefaultFeeTokenSelected || isBridgeTransactionTokenNativeToken
              ? bridgeTransaction?.feeToken?.address
              : "",
            isBridgeTransactionTokenNativeToken
          );

          sendTx = await sdk.signerOrProvider.sendTransaction(sendTxDetails);

          updateBridgeSteps({
            ...bridgeSteps,
            transfer: false,
            processing: true,
            blockExplorer: {
              name: getBlockExplorerNameByChainId(currentNetwork?.chainId),
              hash: sendTx.hash,
              blockExplorerUrl: currentNetwork?.blockExplorerUrl,
            },
          });

          receiptTx = await sendTx.wait();
        } else {
          sendTxDetails = await sdk.send(
            bridgeTransaction?.network?.target?.chainTargetId,
            bridgeTransaction?.token?.address,
            toWei(
              bridgeTransaction?.token?.amount,
              bridgeTransaction?.token?.details?.decimals
            ),
            bridgeTransaction?.recipient,
            isNonDefaultFeeTokenSelected || isBridgeTransactionTokenNativeToken
              ? bridgeTransaction?.feeToken?.address
              : "",
            isBridgeTransactionTokenNativeToken
          );

          sendTx = await sdk.signerOrProvider.sendTransaction(sendTxDetails);

          updateBridgeSteps({
            ...bridgeSteps,
            transfer: false,
            processing: true,
            blockExplorer: {
              name: getBlockExplorerNameByChainId(currentNetwork?.chainId),
              hash: sendTx.hash,
              blockExplorerUrl: currentNetwork?.blockExplorerUrl,
            },
          });

          receiptTx = await sendTx.wait();
        }

        if (receiptTx.status === TxStatus.READY) {
          updateWasTransferSuccessful(true);
          updateBridgeSteps({
            ...bridgeSteps,
            transfer: false,
            processing: false,
          });
        } else {
          updateBridgeSteps({
            ...bridgeSteps,
            transfer: true,
            processing: false,
          });
        }
      } catch (err) {
        console.error(err);
        updateBridgeSteps({
          ...bridgeSteps,
          transfer: true,
          processing: false,
        });
      }
    }
  };

  useEffect(() => {
    const goBackToFirstStepOnAccountChange = () => {
      updateActiveStep(0);
    };

    goBackToFirstStepOnAccountChange();
  }, [account]);

  useEffect(() => {
    const checkForNonApprovableToken = async () => {
      if (sdk && currentNetwork?.chainId) {
        const wrappedTokenList = await sdk.getWrappedTokens(
          currentNetwork?.chainTargetId
        );
        for (const wrappedToken of wrappedTokenList) {
          if (
            wrappedToken?.address?.toLowerCase() ===
            bridgeTransaction?.token?.address.toLowerCase()
          ) {
            setIsTokenNonApprovable(true);
            return;
          }
        }
        setIsTokenNonApprovable(false);
      }
    };
    checkForNonApprovableToken();
  }, [sdk, currentNetwork?.chainId, bridgeTransaction?.token?.address]);

  useEffect(() => {
    const checkServiceFeeTokenApproval = async () => {
      if (sdk && bridgeTransaction?.feeToken?.address && account) {
        try {
          const approved = await sdk.isTokenApproved(
            bridgeTransaction?.feeToken?.address,
            account,
            isBridgeTransactionTokenNativeToken || isNonDefaultFeeTokenSelected,
            toWei(
              bridgeTransaction?.feeToken?.amount,
              bridgeTransaction?.feeToken?.details?.decimals
            )
          );
          setIsServiceTokenApproved(approved);
        } catch (err) {
          console.error(err);
          setIsServiceTokenApproved(false);
        }
      }
    };

    checkServiceFeeTokenApproval();

    return () => {
      setIsServiceTokenApproved(false);
    };
  }, [
    sdk,
    account,
    bridgeTransaction?.feeToken?.address,
    isBridgeTransactionTokenNativeToken,
    isNonDefaultFeeTokenSelected,
  ]);

  useEffect(() => {
    const checkBridgeTokenApproval = async () => {
      if (
        sdk &&
        bridgeTransaction?.token?.address &&
        !["0", "0.0"]?.includes(bridgeTransaction?.token?.amount)
      ) {
        try {
          const approved = await sdk.isTokenApproved(
            bridgeTransaction?.token?.address,
            account,
            isNonDefaultFeeTokenSelected,
            toWei(
              bridgeTransaction?.token?.amount,
              bridgeTransaction?.token?.details?.decimals
            )
          );

          setIsBridgeTokenApproved(
            isBridgeTransactionTokenNativeToken ? true : approved
          );
        } catch (err) {
          console.error(err);
          setIsBridgeTokenApproved(false);
        }
      }
    };
    checkBridgeTokenApproval();
  }, [
    sdk,
    bridgeTransaction?.token?.address,
    bridgeTransaction?.token?.amount,
    isNonDefaultFeeTokenSelected,
  ]);

  useEffect(() => {
    const resetTransactionCleared = () => {
      if (
        tokenSelectedBalance !== "0" ||
        sliderPercentage > 0 ||
        bridgeTransaction?.network?.target?.chainId ||
        bridgeTransaction?.token?.address ||
        bridgeTransaction?.recipient !== account ||
        bridgeTransaction?.feeToken?.address !==
          serviceFeeOptions[0]?.address ||
        selectedToken?.address
      ) {
        setTxFeeEstimation("0.0");
        setTxFeeEstimationCurrency("($ 0.00)");
        updateWasTransactionClear(false);
      }
    };
    resetTransactionCleared();
  }, [
    tokenSelectedBalance,
    sliderPercentage,
    bridgeTransaction?.network?.target?.chainId,
    bridgeTransaction?.token?.address,
    bridgeTransaction?.recipient,
    selectedToken?.address,
  ]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadTransactionEstimation = async () => {
      if (
        sdk &&
        library &&
        tokenSelectedBalance !== "0" &&
        sliderPercentage > 0 &&
        bridgeTransaction?.network?.target?.chainId &&
        bridgeTransaction?.token?.address &&
        bridgeTransaction?.recipient &&
        bridgeTransaction?.feeToken?.address &&
        selectedToken?.address
      ) {
        try {
          const gasPrice = await library.getGasPrice();

          const approved = await sdk.isTokenApproved(
            bridgeTransaction?.token?.address,
            account,
            isBridgeTransactionTokenNativeToken || isNonDefaultFeeTokenSelected,
            toWei(
              bridgeTransaction?.token?.amount,
              bridgeTransaction?.token?.details?.decimals
            )
          );

          if (approved) {
            const gasEstimation = await getSendEstimation(
              sdk,
              bridgeTransaction.network.target.chainTargetId,
              bridgeTransaction.token.address,
              toWei(
                bridgeTransaction.token.amount,
                bridgeTransaction.token.details.decimals
              ),
              bridgeTransaction.recipient,
              isNonDefaultFeeTokenSelected ||
                isBridgeTransactionTokenNativeToken
                ? bridgeTransaction.feeToken.address
                : "",
              isBridgeTransactionTokenNativeToken
            );

            const result = gasEstimation.mul(gasPrice);
            setTxFeeEstimation(
              fromWei(result, currentNetwork?.nativeCurrency?.decimals)
            );
          } else {
            const gasEstimation = await getApproveEstimation(
              sdk,
              bridgeTransaction?.token?.address,
              toWei(
                bridgeTransaction?.token?.amount,
                bridgeTransaction?.token?.details?.decimals
              ),
              isBridgeTransactionTokenNativeToken ||
                isNonDefaultFeeTokenSelected
            );

            const result = gasEstimation.mul(gasPrice);
            setTxFeeEstimation(
              fromWei(result, currentNetwork?.nativeCurrency?.decimals)
            );
          }
        } catch (err) {
          console.error(err);
          setTxFeeEstimation("0.0");
        }
      }
    };
    try {
      loadTransactionEstimation();
    } catch (error) {
      attemptCount++;
      if (attemptCount < maxAttempts) {
        loadTransactionEstimation();
      }
    }
  }, [
    sdk,
    library,
    selectedToken?.address,
    tokenSelectedBalance,
    sliderPercentage,
    bridgeTransaction?.network?.target?.chainId,
    bridgeTransaction?.token?.address,
    bridgeTransaction?.recipient,
    bridgeTransaction?.feeToken?.address,
  ]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadTxFeeEstimationCurrency = async () => {
      if (!["0", "0.0"]?.includes(txFeeEstimation)) {
        const coingeckoId = getCoingeckooIds(
          currentNetwork?.nativeCurrency?.symbol?.toLowerCase()
        )?.id;

        const price = await getTokenPrice(
          coingeckoId,
          config.serviceFeeCurrency
        );

        const formattedPrice = formatCurrency(
          Number(txFeeEstimation) * price,
          TOKEN_FEE_DECIMALS,
          currencySymbols[config.serviceFeeCurrency]
        );
        setTxFeeEstimationCurrency(formattedPrice);
      }
    };
    try {
      loadTxFeeEstimationCurrency();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        loadTxFeeEstimationCurrency();
      }
    }
  }, [txFeeEstimation]);

  useEffect(() => {
    const clearStepperAfterNetworkChange = () => {
      updateActiveStep(0);
      updateBridgeSteps({
        ...bridgeSteps,
        next: true,
      });
      setTokenList([]);
      clearBridgeTransaction();
      setIsTokenEWTB(false);
    };

    clearStepperAfterNetworkChange();
  }, [chainId]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadBalanceIfTokenIsSelected = async () => {
      if (
        sdk &&
        account &&
        selectedToken?.name &&
        bridgeTransaction?.feeToken?.address &&
        bridgeTransaction?.feeToken?.amount
      ) {
        const tokenBalance = (
          await sdk.balanceOfMulticallV2([selectedToken.address], account)
        )[0];
        if (
          selectedToken?.address?.toLowerCase() ===
            bridgeTransaction?.feeToken?.address?.toLowerCase() &&
          !["0", "0.0"].includes(tokenBalance?.toString())
        ) {
          updateTokenSelectedBalance(
            fromWei(
              tokenBalance.sub(toWei(bridgeTransaction?.feeToken?.amount)),
              selectedToken.decimals
            )
          );
        } else {
          updateTokenSelectedBalance(
            fromWei(tokenBalance, selectedToken.decimals)
          );
        }
      }
    };
    try {
      loadBalanceIfTokenIsSelected();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        loadBalanceIfTokenIsSelected();
      }
    }
  }, [
    sdk,
    account,
    selectedToken,
    bridgeTransaction?.feeToken?.address,
    bridgeTransaction?.feeToken?.amount,
  ]);

  useEffect(() => {
    if (serviceFeeOptions?.length > 0) {
      const serviceFeeToken = serviceFeeOptions[0];
      setBridgeTransaction({
        ...bridgeTransaction,
        feeToken: {
          details: {
            name: serviceFeeToken.name,
            symbol: serviceFeeToken.symbol,
            decimals: serviceFeeToken.decimals,
            icon: serviceFeeToken.icon,
          },
          amount: serviceFeeToken.amount,
          address: serviceFeeToken.address,
        },
      });
    }
  }, [serviceFeeOptions]);

  useEffect(() => {
    let attemptCount: number = 0;
    const checkBridgeTransactionTokenIsNativeToken = () => {
      if (sdk) {
        setIsBridgeTransactionTokenNativeToken(
          bridgeTransaction?.token?.address === sdk.proxyRouterAddress
        );
      }
    };
    try {
      checkBridgeTransactionTokenIsNativeToken();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        checkBridgeTransactionTokenIsNativeToken();
      }
    }
  }, [sdk, bridgeTransaction?.token?.address]);

  useEffect(() => {
    let attemptCount: number = 0;
    const checkServiceFeeTokenBalance = async () => {
      if (sdk && library && account && bridgeTransaction?.feeToken?.address) {
        if (bridgeTransaction.feeToken.address === sdk.proxyRouterAddress) {
          const signer = await library.getSigner();
          const nativeTokenBalance = await signer.getBalance();
          const nativeTokenBalanceBN = await toBN(nativeTokenBalance);
          if (nativeTokenBalanceBN && nativeTokenBalance) {
            setNotEnoughBalanceForServiceFee(
              nativeTokenBalanceBN?.lte(
                toWei(bridgeTransaction.feeToken.amount)
              )
            );
          }
        } else if (bridgeTransaction?.feeToken?.address) {
          try {
            const feeTokenBalanceBN = (
              await sdk.balanceOfMulticallV2(
                [bridgeTransaction.feeToken.address],
                account
              )
            )[0];
            if (feeTokenBalanceBN) {
              setNotEnoughBalanceForServiceFee(
                feeTokenBalanceBN?.lt(
                  toWei(
                    bridgeTransaction.feeToken.amount,
                    bridgeTransaction.feeToken.details.decimals
                  )
                )
              );
            }
          } catch (err) {
            console.error(err);
            setServiceFeeOptions([]);
            setNotEnoughBalanceForServiceFee(false);
          }
        }
      }
    };
    try {
      checkServiceFeeTokenBalance();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        checkServiceFeeTokenBalance();
      }
    }

    return () => {
      setNotEnoughBalanceForServiceFee(false);
    };
  }, [sdk, account, library, bridgeTransaction?.feeToken]);

  useEffect(() => {
    let attemptCount: number = 0;
    const checkForWrappedTokenDeployed = async () => {
      if (
        sdk &&
        bridgeTransaction?.network?.target?.chainId &&
        bridgeTransaction?.token?.address
      ) {
        const albtToken = await sdk.getALBTToken();
        const tokenAddress = bridgeTransaction?.token?.address?.toLowerCase();

        if (albtToken?.address?.toLowerCase() === tokenAddress) {
          setNotDeployedWrappedToken(false);
          return;
        }

        const serviceFeeOptionsAddresses: string[] = serviceFeeOptions
          ?.slice(1)
          ?.map((serviceToken) => serviceToken?.address);

        if (
          serviceFeeOptionsAddresses?.includes(
            bridgeTransaction?.token?.address
          )
        ) {
          return;
        }

        const chainTargetId = bridgeTransaction?.network?.source?.chainTargetId;
        const wrappedTokenList = await sdk.getWrappedTokens(chainTargetId);
        for (const symbol in wrappedTokenList) {
          if (
            wrappedTokenList[symbol].nativeToken === tokenAddress ||
            (nativeTokenByWrappedToken !== ({} as NativeTokenWithChainId) &&
              nativeTokenByWrappedToken.chainId === chainTargetId) ||
            isBridgeTransactionTokenNativeToken
          ) {
            setNotDeployedWrappedToken(false);
            return;
          }
        }
        setNotDeployedWrappedToken(true);
      } else {
        setNotDeployedWrappedToken(false);
      }
    };
    try {
      checkForWrappedTokenDeployed();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        checkForWrappedTokenDeployed();
      }
    }

    return () => {
      setNotDeployedWrappedToken(false);
    };
  }, [
    sdk,
    bridgeTransaction?.network?.target?.chainId,
    bridgeTransaction?.token?.address,
  ]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadNativeTokenChain = async () => {
      if (sdk && bridgeTransaction?.token?.address) {
        const nativeToken: NativeTokenWithChainId =
          await sdk.getNativeTokenByWrappedAddress(
            bridgeTransaction?.token?.address
          );
        if (nativeToken.token !== "0x") {
          setTokenIsNotWrapped(false);
          setNativeTokenByWrappedToken(nativeToken);
        } else {
          // Check if token is wrapped version of a token
          setTokenIsNotWrapped(true);
          setNativeTokenByWrappedToken({} as NativeTokenWithChainId);
        }
      } else {
        setTokenIsNotWrapped(false);
        setNativeTokenByWrappedToken({} as NativeTokenWithChainId);
      }
    };

    const checkEWTB = async () => {
      if (sdk && selectedToken?.address && NETWORK_CONFIG["ewc"]?.EWTB) {
        const EWTBAddresses = Object.values(NETWORK_CONFIG["ewc"].EWTB);
        setIsTokenEWTB(
          EWTBAddresses?.includes(selectedToken?.address?.toLowerCase())
        );
      }
    };
    try {
      loadNativeTokenChain();
      checkEWTB();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        loadNativeTokenChain();
        checkEWTB();
      }
    }
  }, [sdk, bridgeTransaction?.token?.address]);

  useEffect(() => {
    let attemptCount: number = 0;
    const loadNativeOrWrappedTokenSymbolToBridge = async () => {
      if (
        sdk &&
        bridgeTransaction?.network?.source?.chainTargetId &&
        bridgeTransaction?.network?.target?.chainTargetId &&
        bridgeTransaction?.token?.details?.name &&
        bridgeTransaction?.token?.address
      ) {
        const nativeToken: NativeTokenWithChainId =
          await sdk.getNativeTokenByWrappedAddress(
            bridgeTransaction.token.address
          );

        if (
          nativeToken.chainId ===
            bridgeTransaction.network.target.chainTargetId &&
          bridgeTransaction.token.details.name.includes("Wrapped")
        ) {
          setNativeOrWrappedTokenSymbolToBridge(
            bridgeTransaction.token.details.symbol?.replace(/W/, "")
          );
          return;
        }

        const sourceWrappedTokenList = await sdk.getWrappedTokens(
          bridgeTransaction?.network?.source?.chainTargetId
        );

        for (const wrappedToken of sourceWrappedTokenList) {
          if (
            wrappedToken?.wrappedToken?.toLowerCase() ===
              bridgeTransaction.token.address?.toLowerCase() &&
            wrappedToken?.sourceChainId ===
              bridgeTransaction?.network?.target?.chainTargetId
          ) {
            setNativeOrWrappedTokenSymbolToBridge(
              wrappedToken?.wrappedTokenSymbol?.replace(/W/, "")
            );
            return;
          }
        }

        if (!bridgeTransaction?.token?.details?.name?.includes("Wrapped")) {
          setNativeOrWrappedTokenSymbolToBridge(
            `W${bridgeTransaction?.token?.details?.symbol}`
          );
        }
      }
    };
    try {
      loadNativeOrWrappedTokenSymbolToBridge();
    } catch (error) {
      attemptCount++;
      console.error(error);
      if (attemptCount < maxAttempts) {
        loadNativeOrWrappedTokenSymbolToBridge();
      }
    }
  }, [
    sdk,
    bridgeTransaction?.network?.source?.chainTargetId,
    bridgeTransaction?.network?.target?.chainTargetId,
    bridgeTransaction?.token?.details?.name,
    bridgeTransaction?.token?.address,
  ]);

  return (
    <div className="bridge-stepper my-4">
      <Stepper>
        <StepDetails
          activeStep={activeStep}
          wasTransactionCleared={wasTransactionCleared}
          tokenSelectedBalance={tokenSelectedBalance}
          tokenList={tokenList}
          selectedToken={selectedToken}
          sliderPercentage={sliderPercentage}
          isServiceTokenApproved={isServiceTokenApproved}
          isBridgeTokenApproved={isBridgeTokenApproved}
          serviceFeeTokenIndex={serviceFeeTokenIndex}
          txFeeEstimation={txFeeEstimation}
          txFeeEstimationCurrency={txFeeEstimationCurrency}
          notEnoughBalanceForServiceFee={notEnoughBalanceForServiceFee}
          isBridgeTransactionTokenNativeToken={
            isBridgeTransactionTokenNativeToken
          }
          isTokenEWTB={isTokenEWTB}
          notDeployedWrappedToken={notDeployedWrappedToken}
          tokenIsNotWrapped={tokenIsNotWrapped}
          updateSliderPercentage={updateSliderPercentage}
          updateSelectedToken={updateSelectedToken}
          updateTokenSelectedBalance={updateTokenSelectedBalance}
          updateTokenList={setTokenList}
          onSelectedFeeTokenChange={onSelectedFeeTokenChange}
        />
        {activeStep === 1 ? (
          <StepReview
            activeStep={activeStep}
            isServiceTokenApproved={isServiceTokenApproved}
            isBridgeTokenApproved={isBridgeTokenApproved}
            txFeeEstimation={txFeeEstimation}
            txFeeEstimationCurrency={txFeeEstimationCurrency}
            nativeOrWrappedTokenSymbolToBridge={
              nativeOrWrappedTokenSymbolToBridge
            }
          />
        ) : (
          <></>
        )}
        <StepBridgeButtons
          notEnoughBalanceForServiceFee={notEnoughBalanceForServiceFee}
          isTokenEWTB={isTokenEWTB}
          tokenIsNotWrapped={tokenIsNotWrapped}
          isServiceTokenApproved={isServiceTokenApproved}
          isBridgeTokenApproved={isBridgeTokenApproved}
          bridgeSteps={bridgeSteps}
          updateBridgeSteps={updateBridgeSteps}
          updateActiveStep={updateActiveStep}
          approveServiceFeeToken={approveServiceFeeToken}
          approveBridgeToken={approveBridgeToken}
          sendBridgeToken={sendBridgeToken}
          clearBridgeTransaction={clearBridgeTransaction}
        />
      </Stepper>
    </div>
  );
};

export default StepperBridge;
