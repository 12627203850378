import { ethers } from "ethers";
import { getTokenIcon, svgUrlExists } from "utils";
import { getNetworkByChainTargetId } from "utils/network";

function formatTransferList(
  sdk: any,
  etherscanBaseURL: string,
  claims: Claim[],
  networkOptions: Network[]
) {
  let status, amount, claimCallback;

  const transfers = claims.map((claim: any) => {
    const { transfer, isClaimed } = claim;
    status = isClaimed;
    amount = ethers.utils.formatUnits(
      transfer?.amount,
      transfer?.wrappedTokenDecimals
    );

    claimCallback = async () => {
      return await claimTx(sdk, transfer);
    };

    const bridgedTokenIcon = getTokenIcon({
      decimals: transfer.wrappedTokenDecimals,
      symbol: transfer.wrappedTokenSymbol,
      name: transfer.wrappedTokenName,
    });

    return {
      sourceNetwork: getNetworkByChainTargetId(
        transfer.sourceChainId,
        networkOptions
      ),
      targetNetwork: getNetworkByChainTargetId(
        transfer.targetChainId,
        networkOptions
      ),
      value: `${amount} ${transfer.wrappedTokenSymbol}`,
      status,
      transactionURL: etherscanBaseURL,
      majorityReached: transfer.majorityReached,
      sourceTx: transfer.sourceTx,
      claimCallback,
      nativeChainId: transfer.nativeChainId,
      nativeToken: transfer.nativeToken,
      recipient: transfer.recipient,
      bridgedToken: {
        details: {
          decimals: transfer.wrappedTokenDecimals,
          symbol: transfer.wrappedTokenSymbol,
          name: transfer.wrappedTokenName,
          icon: svgUrlExists(`/icons/${bridgedTokenIcon}.svg`)
            ? bridgedTokenIcon
            : "assets",
        },
        amount: amount,
        address: "",
      },
      globalHash: transfer.globalHash,
    };
  });

  return transfers;
}

async function claimTx(sdk: any, transaction: any) {
  return await sdk.claim(transaction);
}

async function getFormattedSdkClaims(
  sdk: any,
  account: string,
  chainId: number
) {
  const latestClaims: ClaimsSDK = await sdk.getClaims(account, chainId);

  const formattedClaims: Claim[] = await formatMulticallClaims(
    sdk,
    latestClaims.claims
  );
  return formattedClaims;
}

function getClaimCounters(claims: Claim[]) {
  let counterClaimed = 0;
  let counterNotClaimed = 0;
  if (claims) {
    claims.map((claim: Claim) => {
      if (!claim.isClaimed && claim.transfer.majorityReached) {
        counterClaimed++;
      } else {
        counterNotClaimed++;
      }
    });
  }

  return { counterClaimed, counterNotClaimed };
}
async function formatMulticallClaims(sdk: any, claims: Transfer[]) {
  const result = [];
  try {
    const statuses = await sdk.isClaimedMulticall(claims);
    for (let index = 0; index < statuses.length; index++) {
      const transfer = claims[index];
      const isClaimed = statuses[index];
      if (transfer.transferType === 2) {
        const tokenDetails = await sdk.getTokenDetails(transfer.nativeToken);
        transfer.wrappedTokenDecimals = tokenDetails.decimals;
        transfer.wrappedTokenName = tokenDetails.name;
        transfer.wrappedTokenSymbol = tokenDetails.symbol;
      }
      result.push({
        transfer,
        isClaimed,
      });
    }
    return result;
  } catch (error: any) {
    console.error(error);
  }
  return result;
}

export { claimTx, formatTransferList, getClaimCounters, getFormattedSdkClaims };
